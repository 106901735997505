import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PATH, EVENT_LOGIN_REDIRECT } from './constants';
import storage from './helpers/storage';


const RedirectLogin: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.addEventListener(EVENT_LOGIN_REDIRECT, () => {
            navigate("/login", {replace: true});
        });
        const token: string | null = storage.getToken();
        if (token === null) {
            navigate("/login", { replace: true });
        }
    }, []);

    return null; // This component doesn't render anything
};

export default RedirectLogin;
